<nav [ngClass]="{'open': !(menuCollapsed | async)}" [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')"
  class="navbar navbar-expand-md navbar-light p-0 navbar-container" role="navigation" [attr.aria-label]="'nav.main.description' | translate">
  <div class="container h-100">
    <a class="navbar-brand my-2" routerLink="/home">
      <img src="assets/dspace/images/eme-logo.png" [attr.alt]="'menu.header.image.logo' | translate" />
    </a>
    <div id="collapsingNav" class="w-100 h-100">
      <ul class="navbar-nav navbar-navigation me-auto mb-2 mb-lg-0 h-100 d-flex align-items-center">
        <ng-container *ngFor="let section of (sections | async)">
          <ng-container *ngComponentOutlet="(sectionMap$ | async).get(section.id)?.component; injector: (sectionMap$ | async).get(section.id)?.injector;"></ng-container>
        </ng-container>
      </ul>
    </div>
    <ds-search-navbar class="navbar-collapsed"></ds-search-navbar>
    <ds-lang-switch class="navbar-collapsed"></ds-lang-switch>
    <ds-themed-auth-nav-menu class="navbar-collapsed"></ds-themed-auth-nav-menu>
    <ds-impersonate-navbar class="navbar-collapsed"></ds-impersonate-navbar>
  </div>
</nav>
<!--
<nav _ngcontent-sc363="" class="navbar navbar-expand-sm bg-primary text-white justify-content-center ng-tns-c363-5">
  <div _ngcontent-sc363="" class="row gx-5 ng-tns-c363-5">
    <ul _ngcontent-sc363="" class="navbar-nav ng-tns-c363-5">
      <li _ngcontent-sc363="" class="ng-tns-c363-5">
        <a _ngcontent-sc363="" href="https://eda.eme.ro/handle/2437/1" class="nav-link text-white ng-tns-c363-5">Digitális könyvtár</a>
      </li>
      <li _ngcontent-sc363="" class="ng-tns-c363-5">
        <a _ngcontent-sc363="" href="https://eda.eme.ro/handle/2437/195023" class="nav-link text-white ng-tns-c363-5">Hallgatói dolgozatok</a>
      </li>
      <li _ngcontent-sc363="" class="ng-tns-c363-5">
        <a _ngcontent-sc363="" href="https://eda.eme.ro/handle/2437/2265" class="nav-link text-white ng-tns-c363-5">PhD dolgozatok</a>
      </li>
      <li _ngcontent-sc363="" class="ng-tns-c363-5">
        <a _ngcontent-sc363="" href="https://eda.eme.ro/handle/2437/198859" class="nav-link text-white ng-tns-c363-5">Publikációk</a>
      </li>
    </ul>
  </div>
  </nav>
-->
