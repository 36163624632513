<div class="background-image-container">
    <div class="container">
      <div class="jumbotron jumbotron-fluid">
        <div class="d-flex flex-wrap">
          <div>
           <br>
           <br>
           <br>
           <br>
          </div>
        </div>
      </div>
    </div>
    <picture class="background-image">
      <source type="image/jpg" srcset="assets/dspace/images/banner.png 2000w, assets/dspace/images/banner-half.png 1200w, assets/dspace/images/banner-tall.png 768w">
      <img alt="" [src]="'assets/dspace/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
    </picture>
  </div>
  
